import React, { useState } from "react";
import { Form, Input, Button, Select, Upload, message } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import { useAuth } from "../utils/useAuth";
import { useNavigate } from "react-router-dom";

const { Option } = Select;
const { Dragger } = Upload;

const VendorSignup = () => {
	const { apiurl, handleLogin } = useAuth();
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();

	const onFinish = async (values) => {
		const formData = new FormData();

		Object.entries(values).forEach(([key, value]) => {
			if (
				key === "business_registration_certificate" ||
				key === "owner_identification"
			) {
				if (Array.isArray(value)) {
					formData.append(key, value[0]?.originFileObj);
				}
			} else if (key === "product_categories") {
				formData.append(key, JSON.stringify(value));
			} else {
				formData.append(key, value);
			}
		});

		setLoading(true);

		try {
			const response = await fetch(`${apiurl}/vendor/`, {
				method: "POST",
				body: formData,
			});
			const data = await response.json();
			if (response.ok) {
				message.success("Vendor signed up successfully!");
				handleLogin(data.access_token);
				navigate("/home");
			} else {
				const errorData = await response.json();
				message.error(errorData.detail || "Error signing up vendor.");
			}
		} catch (error) {
			message.error("Something went wrong. Please try again.");
		} finally {
			setLoading(false);
		}
	};

	return (
		<Form
			layout="vertical"
			onFinish={onFinish}
			style={{ maxWidth: 600, margin: "0 auto" }}>
			<Form.Item
				label="Contact Name"
				name="contact_name"
				rules={[{ required: true, message: "Contact name is required." }]}>
				<Input placeholder="Enter contact name" />
			</Form.Item>
			<Form.Item
				label="Contact Phone"
				name="contact_phone"
				rules={[{ required: true, message: "Contact phone is required." }]}>
				<Input placeholder="Enter contact phone" />
			</Form.Item>
			<Form.Item
				label="Contact Email"
				name="contact_email"
				rules={[
					{ required: true, message: "Contact email is required." },
					{ type: "email", message: "Enter a valid email." },
				]}>
				<Input placeholder="Enter contact email" />
			</Form.Item>
			<Form.Item
				label="Year of Establishment"
				name="year_of_establishment"
				rules={[{ required: true, message: "Year is required." }]}>
				<Input type="number" placeholder="Enter year" />
			</Form.Item>
			<Form.Item
				label="Business Type"
				name="business_type"
				rules={[{ required: true, message: "Business type is required." }]}>
				<Select placeholder="Select business type">
					<Option value="Sole Proprietorship">Sole Proprietorship</Option>
					<Option value="Partnership">Partnership</Option>
					<Option value="LLC">LLC</Option>
					<Option value="Corporation">Corporation</Option>
				</Select>
			</Form.Item>
			<Form.Item
				label="Business Address"
				name="business_address"
				rules={[{ required: true, message: "Business address is required." }]}>
				<Input.TextArea placeholder="Enter business address" rows={4} />
			</Form.Item>
			<Form.Item label="Warehouse Address" name="warehouse_address">
				<Input.TextArea placeholder="Enter warehouse address" rows={4} />
			</Form.Item>
			<Form.Item
				label="Business Registration Certificate"
				name="business_registration_certificate"
				valuePropName="fileList"
				getValueFromEvent={(e) => (Array.isArray(e) ? e : e && e.fileList)}
				rules={[{ required: true, message: "Certificate is required." }]}>
				<Dragger
					beforeUpload={() => false}
					accept=".pdf,.jpg,.jpeg,.png"
					maxCount={1}>
					<p className="ant-upload-drag-icon">
						<InboxOutlined />
					</p>
					<p className="ant-upload-text">
						Click or drag file to this area to upload
					</p>
				</Dragger>
			</Form.Item>
			<Form.Item
				label="Owner Identification"
				name="owner_identification"
				valuePropName="fileList"
				getValueFromEvent={(e) => (Array.isArray(e) ? e : e && e.fileList)}
				rules={[{ required: true, message: "Owner ID is required." }]}>
				<Dragger
					beforeUpload={() => false}
					accept=".pdf,.jpg,.jpeg,.png"
					maxCount={1}>
					<p className="ant-upload-drag-icon">
						<InboxOutlined />
					</p>
					<p className="ant-upload-text">
						Click or drag file to this area to upload
					</p>
				</Dragger>
			</Form.Item>
			<Form.Item
				label="Bank Name"
				name="bank_name"
				rules={[{ required: true, message: "Bank name is required." }]}>
				<Input placeholder="Enter bank name" />
			</Form.Item>
			<Form.Item
				label="Bank Account Name"
				name="bank_account_name"
				rules={[{ required: true, message: "Bank account name is required." }]}>
				<Input placeholder="Enter bank account name" />
			</Form.Item>
			<Form.Item
				label="Business Name"
				name="business_name"
				rules={[{ required: true, message: "Business name is required." }]}>
				<Input placeholder="Enter Business name" />
			</Form.Item>
			<Form.Item
				label="Tax Identification Number"
				name="tax_identification_number"
				rules={[
					{ required: true, message: "Tax Identification Number is required." },
				]}>
				<Input placeholder="Enter Tax Identification Number" />
			</Form.Item>
			<Form.Item
				label="SWIFT Code"
				name="swift_code"
				rules={[{ required: true, message: "SWIFT code is required." }]}>
				<Input placeholder="Enter SWIFT code" />
			</Form.Item>
			<Form.Item
				label="Account Number"
				name="account_number"
				rules={[{ required: true, message: "Account number is required." }]}>
				<Input placeholder="Enter account number" />
			</Form.Item>
			<Form.Item
				label="Branch"
				name="branch"
				rules={[{ required: true, message: "Branch is required." }]}>
				<Input placeholder="Enter branch" />
			</Form.Item>
			<Form.Item
				label="IFSC Code"
				name="ifsc_code"
				rules={[{ required: true, message: "IFSC code is required." }]}>
				<Input placeholder="Enter IFSC code" />
			</Form.Item>
			<Form.Item label="Operating Hours" name="operating_hours">
				<Input placeholder="Enter operating hours" />
			</Form.Item>
			<Form.Item
				label="Product Categories"
				name="product_categories"
				rules={[{ required: true, message: "Select product categories." }]}>
				<Select mode="multiple" placeholder="Select categories">
					<Option value="1">Electronics</Option>
					<Option value="2">Fashion</Option>
					<Option value="3">Groceries</Option>
				</Select>
			</Form.Item>
			<Form.Item>
				<Button type="primary" htmlType="submit" loading={loading}>
					Submit
				</Button>
			</Form.Item>
		</Form>
	);
};

export default VendorSignup;
