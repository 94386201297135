import React, { useState, useEffect } from "react";
import {
	Card,
	Row,
	Col,
	Carousel,
	Typography,
	Tag,
	Space,
	Checkbox,
	Button,
	Input,
	Slider,
	Spin,
	List,
} from "antd";
import { useAuth } from "../utils/useAuth";
import "./Products.css";

const { Title, Text } = Typography;

const ProductGallery = () => {
	const { apiurl } = useAuth();
	const [products, setProducts] = useState([]);
	const [categories, setCategories] = useState([]);
	const [subCategories, setSubCategories] = useState([]);
	const [colors, setColors] = useState([]);
	const [vendors, setVendors] = useState([]);

	const [filteredProducts, setFilteredProducts] = useState([]);
	const [selectedCategories, setSelectedCategories] = useState([]);
	const [selectedSubCategories, setSelectedSubCategories] = useState([]);
	const [selectedColors, setSelectedColors] = useState([]);
	const [selectedVendors, setSelectedVendors] = useState([]);
	const [searchQuery, setSearchQuery] = useState("");
	const [priceRange, setPriceRange] = useState([0, 1000]);
	const [loading, setLoading] = useState(false);

	const [showAllCategories, setShowAllCategories] = useState(false);
	const [showAllSubCategories, setShowAllSubCategories] = useState(false);
	const [showAllColors, setShowAllColors] = useState(false);
	const [showAllVendors, setShowAllVendors] = useState(false);

	useEffect(() => {
		fetchInitialData();
	}, []);

	useEffect(() => {
		filterProducts();
	}, [
		selectedCategories,
		selectedSubCategories,
		selectedColors,
		selectedVendors,
		searchQuery,
		priceRange,
		products,
	]);

	const fetchInitialData = async () => {
		setLoading(true);
		try {
			const [
				productsRes,
				categoriesRes,
				subCategoriesRes,
				colorsRes,
				vendorsRes,
			] = await Promise.all([
				fetch(`${apiurl}/products`).then((res) => res.json()),
				fetch(`${apiurl}/categories`).then((res) => res.json()),
				fetch(`${apiurl}/subcategories`).then((res) => res.json()),
				fetch(`${apiurl}/colors`).then((res) => res.json()),
				fetch(`${apiurl}/filtervendors`).then((res) => res.json()),
			]);

			setProducts(productsRes);
			setFilteredProducts(productsRes);
			setCategories(categoriesRes);
			setSubCategories(subCategoriesRes);
			setColors(colorsRes);
			setVendors(vendorsRes);
		} catch (error) {
			console.error("Failed to fetch data:", error);
		} finally {
			setLoading(false);
		}
	};

	const filterProducts = () => {
		let updatedProducts = [...products];

		if (selectedCategories.length > 0) {
			updatedProducts = updatedProducts.filter((product) =>
				selectedCategories.includes(product.category?.id)
			);
		}

		if (selectedSubCategories.length > 0) {
			updatedProducts = updatedProducts.filter((product) =>
				selectedSubCategories.includes(product.sub_category?.id)
			);
		}

		if (selectedColors.length > 0) {
			updatedProducts = updatedProducts.filter((product) =>
				product.product_colors.some((color) =>
					selectedColors.includes(color.color.id)
				)
			);
		}

		if (selectedVendors.length > 0) {
			updatedProducts = updatedProducts.filter((product) =>
				selectedVendors.includes(product.vendor?.id)
			);
		}

		if (searchQuery) {
			updatedProducts = updatedProducts.filter((product) =>
				product.name.toLowerCase().includes(searchQuery.toLowerCase())
			);
		}

		updatedProducts = updatedProducts.filter((product) =>
			product.product_colors.some(
				(color) =>
					parseFloat(color.price) >= priceRange[0] &&
					parseFloat(color.price) <= priceRange[1]
			)
		);

		setFilteredProducts(updatedProducts);
	};

	const onPriceRangeChange = (value) => {
		setPriceRange(value);
	};

	const renderFilterCheckboxes = (
		items,
		selectedItems,
		setSelectedItems,
		showAll,
		setShowAll
	) => {
		const displayedItems = showAll ? items : items.slice(0, 6);

		const handleChange = (checkedValue, itemId) => {
			const updatedSelection = checkedValue
				? [...selectedItems, itemId]
				: selectedItems.filter((id) => id !== itemId);
			setSelectedItems(updatedSelection);
		};

		return (
			<>
				<div>
					{displayedItems.map((item) => (
						<Checkbox
							className="check-box"
							key={item.id}
							checked={selectedItems.includes(item.id)}
							onChange={(e) => handleChange(e.target.checked, item.id)}>
							{item.name || item.business_name}
						</Checkbox>
					))}
				</div>
				{items.length > 6 && (
					<Button type="link" onClick={() => setShowAll(!showAll)}>
						{showAll ? "Show Less" : "Show More"}
					</Button>
				)}
			</>
		);
	};

	const ColorPriceDisplay = ({ colors }) => {
		const displayedColors = colors.slice(0, 3);
		const remainingColors = colors.length - 3;

		return (
			<div className="product-colors">
				{/* <div className="sec-heading">Colors:</div> */}
				<div className="colors">
					{displayedColors.map((color) => (
						<div
							className="color"
							key={color.id}
							style={{
								backgroundColor: color.color.name.toLowerCase(),
							}}></div>
					))}
					{remainingColors > 0 && <span>+{remainingColors}</span>}
				</div>
			</div>
		);
	};

	return (
		<div style={{ padding: "20px" }}>
			<Title level={2}>Product Gallery</Title>
			{loading ? (
				<Spin size="large" />
			) : (
				<div className="products-main">
					<div className="filters">
						<div className="categories">
							<Title level={5}>Categories</Title>
							{renderFilterCheckboxes(
								categories,
								selectedCategories,
								setSelectedCategories,
								showAllCategories,
								setShowAllCategories
							)}
						</div>
						<div className="sub-categories">
							<Title level={5}>Subcategories</Title>
							{renderFilterCheckboxes(
								subCategories,
								selectedSubCategories,
								setSelectedSubCategories,
								showAllSubCategories,
								setShowAllSubCategories
							)}
						</div>
						<div className="colors">
							<Title level={5}>Colors</Title>
							{renderFilterCheckboxes(
								colors,
								selectedColors,
								setSelectedColors,
								showAllColors,
								setShowAllColors
							)}
						</div>
						<div className="vendors">
							<Title level={5}>Vendors</Title>
							{renderFilterCheckboxes(
								vendors,
								selectedVendors,
								setSelectedVendors,
								showAllVendors,
								setShowAllVendors
							)}
						</div>

						<div className="search">
							<Title level={5}>Seach By Name</Title>
							<Input
								placeholder="Search by Name"
								onChange={(e) => setSearchQuery(e.target.value)}
							/>
						</div>
						<div className="price">
							<Title level={5}>Price Range</Title>
							<Slider
								range
								min={0}
								max={1000}
								defaultValue={priceRange}
								onChange={onPriceRangeChange}
							/>
						</div>
					</div>

					<List
						itemLayout="vertical"
						pagination={{
							onChange: (page) => {},
							pageSize: 3,
						}}
						className="products-list"
						dataSource={filteredProducts}
						renderItem={(product) => (
							<List.Item key={product.id}>
								{product.product_colors.length > 0 &&
								product.product_colors[0].images.length > 0 ? (
									<Carousel autoplay>
										{product.product_colors[0].images.map((img) => (
											<img
												key={img.id}
												src={`${apiurl}${img.image}`}
												alt={product.name}
											/>
										))}
									</Carousel>
								) : (
									<div
										className="no-img"
										style={{
											backgroundColor: "#f0f0f0",
										}}></div>
								)}
								<div className="product-meta">
									<div className="product-name">{product.name}</div>
									<div className="product-tags">
										{product.vendor && (
											<div className="tags">
												<Tag color="pink">{product.vendor.business_name}</Tag>
											</div>
										)}
										<div>
											$
											{product.product_colors.length > 0
												? product.product_colors[0].price
												: "N/A"}
										</div>
									</div>
									<ColorPriceDisplay colors={product.product_colors} />
								</div>
							</List.Item>
						)}
						locale={{ emptyText: "No products found matching your filters." }}
					/>
				</div>
			)}
		</div>
	);
};

export default ProductGallery;
