import React, { useState } from "react";
import { Input, Button, message, Tabs } from "antd";
import { GoogleLogin, GoogleOAuthProvider } from "@react-oauth/google";
import { useAuth } from "../utils/useAuth";
import { useNavigate } from "react-router-dom";

const { TabPane } = Tabs;

const SignupForm = () => {
	const { apiurl, handleLogin } = useAuth();
	const [loading, setLoading] = useState(false);
	const [otpSent, setOtpSent] = useState(false);
	const [loginMethod, setLoginMethod] = useState(null);
	const [otpResentLoading, setOtpResentLoading] = useState(false);
	const navigate = useNavigate();
	const [formData, setFormData] = useState({
		email: "",
		phone: "",
		otp: "",
	});

	const handleInputChange = (e) => {
		const { name, value } = e.target;
		setFormData((prev) => ({ ...prev, [name]: value }));
	};

	
	const sendOTP = async (method) => {
		setLoading(true);
		try {
			const response = await fetch(`${apiurl}/sendotp/`, {
				method: "POST",
				headers: { "Content-Type": "application/json" },
				body: JSON.stringify({
					[method]: formData[method],
					otp_method: method === "email" ? "email_otp" : "mobile_otp",
				}),
			});
			const data = await response.json();
			if (response.ok) {
				setOtpSent(true);
				setLoginMethod(method);
				message.success(data.message || "OTP sent successfully");
			} else {
				message.error(data.error || "Failed to send OTP");
			}
		} catch (error) {
			message.error("An error occurred while sending OTP");
		} finally {
			setLoading(false);
		}
	};

	
	const verifyOTP = async () => {
		setLoading(true);
		try {
			const response = await fetch(`${apiurl}/login/`, {
				method: "POST",
				headers: { "Content-Type": "application/json" },
				body: JSON.stringify({
					[loginMethod]: formData[loginMethod],
					otp: formData.otp,
					login_method: `${loginMethod}_otp`,
				}),
			});
			const data = await response.json();
			if (response.ok) {
				message.success("Registered successfully!");
				handleLogin(data.access_token);
				navigate("/home");
			} else {
				message.error(data.error || "Invalid OTP or OTP expired");
			}
		} catch (error) {
			message.error("An error occurred while verifying OTP");
		} finally {
			setLoading(false);
		}
	};

	
	const handleResendOTP = async () => {
		setOtpResentLoading(true); 
		try {
			const response = await fetch(`${apiurl}/sendotp/`, {
				method: "POST",
				headers: { "Content-Type": "application/json" },
				body: JSON.stringify({
					email: formData.email,
					phone: formData.phone,
					username: formData.username,
					otp_method: "both", 
				}),
			});

			const data = await response.json();

			if (response.ok) {
				message.success("OTP resent successfully");
			} else {
				message.error(data.error || "Failed to resend OTP");
			}
		} catch (error) {
			message.error("An error occurred while resending OTP");
		} finally {
			setOtpResentLoading(false); 
		}
	};
	
	const handleGoogleLogin = async (response) => {
		setLoading(true);
		try {
			const googleToken = response.credential;
			const res = await fetch(`${apiurl}/signup/`, {
				method: "POST",
				headers: { "Content-Type": "application/json" },
				body: JSON.stringify({
					google_token: googleToken,
					signup_method: "google",
				}),
			});
			const data = await res.json();
			if (res.ok) {
				message.success("Logged in with Google successfully");
				handleLogin(data.access_token);
				navigate("/home");
			} else {
				message.error(data.error || "Google login failed");
			}
		} catch (error) {
			message.error("An error occurred during Google login");
		} finally {
			setLoading(false);
		}
	};

	return (
		<div>
			<Tabs defaultActiveKey="1" onChange={(key) => setOtpSent(false)}>
				{/* Tab for Email Signup/Login */}
				<TabPane tab="Email" key="1">
					<Input
						placeholder="Email"
						name="email"
						value={formData.email}
						onChange={handleInputChange}
						style={{ marginBottom: 8 }}
					/>
					{otpSent && loginMethod === "email" ? (
						<>
							<Input
								placeholder="Enter OTP"
								name="otp"
								value={formData.otp}
								onChange={handleInputChange}
								style={{ marginBottom: 8 }}
							/>
							<Button
								type="primary"
								loading={loading}
								onClick={verifyOTP}
								style={{ width: "100%" }}>
								Verify OTP
							</Button>
							<Button
								type="link"
								onClick={handleResendOTP}
								disabled={loading}
								style={{ width: "100%" }}>
								Resend OTP
							</Button>
						</>
					) : (
						<Button
							type="primary"
							loading={loading}
							onClick={() => sendOTP("email")}
							style={{ width: "100%" }}>
							Send OTP to Email
						</Button>
					)}
				</TabPane>

				{/* Tab for Phone Signup/Login */}
				<TabPane tab="Phone" key="2">
					<Input
						placeholder="Phone Number"
						name="phone"
						value={formData.phone}
						onChange={handleInputChange}
						style={{ marginBottom: 8 }}
					/>
					{otpSent && loginMethod === "phone" ? (
						<>
							<Input
								placeholder="Enter OTP"
								name="otp"
								value={formData.otp}
								onChange={handleInputChange}
								style={{ marginBottom: 8 }}
							/>
							<Button
								type="primary"
								loading={loading}
								onClick={verifyOTP}
								style={{ width: "100%" }}>
								Verify OTP
							</Button>
							<Button
								type="link"
								onClick={handleResendOTP}
								disabled={loading}
								style={{ width: "100%" }}>
								Resend OTP
							</Button>
						</>
					) : (
						<Button
							type="primary"
							loading={loading}
							onClick={() => sendOTP("phone")}
							style={{ width: "100%" }}>
							Send OTP to Phone
						</Button>
					)}
				</TabPane>
			</Tabs>

			{/* Google Login */}
			<GoogleOAuthProvider clientId="925034395163-83rjh4dmhpc4n0jl45oub1oarac5mqr4.apps.googleusercontent.com">
				<GoogleLogin
					onSuccess={handleGoogleLogin}
					onError={() => message.error("Google login failed")}
				/>
			</GoogleOAuthProvider>
		</div>
	);
};

export default SignupForm;
