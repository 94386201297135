import { Route, Routes } from "react-router-dom";
import "./App.css";
import { AuthRoute } from "./components/utils/AuthRoute";
import Login from "./components/userauth/login";
import MainHome from "./components/Home/Home";
import VendorSignup from "./components/vendor/Signup";
import Signup from "./components/userauth/signup";
import AddProduct from "./components/vendor/AddProduct";
import ProductList from "./components/user/Products";
import Commingsoon from "./components/commingsoon/Commingsoon";

function App() {
	return (
		<>
			<Routes>
				<Route path="/" element={<Commingsoon />}></Route>
				{/* <Route path="/login" element={<Login />} />
				<Route path="/signup" element={<Signup />} />
				<Route path="/products" element={<ProductList />} />
				<Route path="/vendor/signup" element={<VendorSignup />} />
				<Route path="/" element={<AuthRoute />}>
					<Route path="/" element={<MainHome />} />
					<Route path="/vendor/addproduct" element={<AddProduct />} />
				</Route> */}
			</Routes>
		</>
	);
}

export default App;
