import React, { useState, useEffect } from "react";
import { Form, Input, Button, Select, Upload, message } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { useAuth } from "../utils/useAuth";
import "./AddProduct.css";
import Main from "./Layout";

const { Option } = Select;

const AddProduct = () => {
	const { apiurl, token } = useAuth();
	const [form] = Form.useForm();
	const [loading, setLoading] = useState(false);
	const [mainCategories, setMainCategories] = useState([]);
	const [categories, setCategories] = useState([]);
	const [subCategories, setSubCategories] = useState([]);
	const [colors, setColors] = useState([]);
	const [categoryLoading, setCategoryLoading] = useState(false);
	const [subCategoryLoading, setSubCategoryLoading] = useState(false);
	const [colorLoading, setColorLoading] = useState(false);

	useEffect(() => {
		const fetchMainCategories = async () => {
			try {
				const response = await fetch(`${apiurl}/maincategories`, {
					method: "GET",
					headers: {
						Authorization: `Bearer ${token}`,
					},
				});
				const data = await response.json();
				if (response.ok) {
					setMainCategories(data);
				} else {
					message.error(data.error || "Failed to fetch main categories");
				}
			} catch (error) {
				message.error("Error fetching main categories");
			}
		};

		const fetchCategories = async () => {
			setCategoryLoading(true);
			try {
				const response = await fetch(`${apiurl}/categories`, {
					method: "GET",
					headers: {
						Authorization: `Bearer ${token}`,
					},
				});
				const data = await response.json();
				if (response.ok) {
					setCategories(data);
				} else {
					message.error(data.error || "Failed to fetch categories");
				}
			} catch (error) {
				message.error("Error fetching categories");
			} finally {
				setCategoryLoading(false);
			}
		};

		const fetchSubCategories = async () => {
			setSubCategoryLoading(true);
			try {
				const response = await fetch(`${apiurl}/subcategories`, {
					method: "GET",
					headers: {
						Authorization: `Bearer ${token}`,
					},
				});
				const data = await response.json();
				if (response.ok) {
					setSubCategories(data);
				} else {
					message.error(data.error || "Failed to fetch subcategories");
				}
			} catch (error) {
				message.error("Error fetching subcategories");
			} finally {
				setSubCategoryLoading(false);
			}
		};

		const fetchColors = async () => {
			setColorLoading(true);
			try {
				const response = await fetch(`${apiurl}/colors`, {
					method: "GET",
					headers: {
						Authorization: `Bearer ${token}`,
					},
				});
				const data = await response.json();
				if (response.ok) {
					setColors(data);
				} else {
					message.error(data.error || "Failed to fetch colors");
				}
			} catch (error) {
				message.error("Error fetching colors");
			} finally {
				setColorLoading(false);
			}
		};

		fetchMainCategories();
		fetchCategories();
		fetchSubCategories();
		fetchColors();
	}, [apiurl, token]);

	const handleSubmit = async (values) => {
		setLoading(true);
		try {
			const formData = new FormData();

			formData.append("name", values.name);
			formData.append("maincategory_id", values.maincategory);
			formData.append("category_id", values.category);

			if (values.subCategory) {
				formData.append("sub_category_id", values.subCategory);
			}

			formData.append("description", values.description);
			formData.append("is_special_collection", values.isSpecialCollection);

			const colors = values.colors.map((color) => ({
				color_id: color.colorId,
				stock_quantity: color.stockQuantity,
				price: color.price,
				size: color.size,
			}));
			formData.append("varients", JSON.stringify(colors));

			values.colors.forEach((color) => {
				if (color.images && Array.isArray(color.images)) {
					color.images.forEach((file) => {
						formData.append(`images_${color.colorId}`, file.originFileObj);
					});
				}
			});

			const response = await fetch(`${apiurl}/products/`, {
				method: "POST",
				headers: {
					Authorization: `Bearer ${token}`,
				},
				body: formData,
			});

			const data = await response.json();

			if (response.ok) {
				message.success("Product added successfully!");
				form.resetFields();
			} else {
				message.error(data.error || "Failed to add product.");
			}
		} catch (error) {
			message.error("An unexpected error occurred.");
		} finally {
			setLoading(false);
		}
	};

	return (
		<Main>
			<div className="add-product">
				<Form
					form={form}
					layout="vertical"
					onFinish={handleSubmit}
					className="add-product__form">
					{/* Main Form */}
					<div className="add-product__form-main">
						<Form.Item
							label="Product Name"
							name="name"
							rules={[
								{ required: true, message: "Please enter the product name" },
							]}
							className="add-product__form-item">
							<Input placeholder="Enter product name" />
						</Form.Item>

						<Form.Item
							label="Main Category"
							name="maincategory"
							rules={[
								{ required: true, message: "Please select a main category" },
							]}
							className="add-product__form-item">
							<Select
								placeholder="Select main category"
								loading={categoryLoading}>
								{mainCategories.map((category) => (
									<Option key={category.id} value={category.id}>
										{category.name}
									</Option>
								))}
							</Select>
						</Form.Item>

						<Form.Item
							label="Category"
							name="category"
							rules={[{ required: true, message: "Please select a category" }]}
							className="add-product__form-item">
							<Select placeholder="Select category" loading={categoryLoading}>
								{categories.map((category) => (
									<Option key={category.id} value={category.id}>
										{category.name}
									</Option>
								))}
							</Select>
						</Form.Item>

						<Form.Item
							label="Sub Category"
							name="subCategory"
							className="add-product__form-item">
							<Select
								placeholder="Select sub-category"
								loading={subCategoryLoading}>
								{subCategories.map((subcategory) => (
									<Option key={subcategory.id} value={subcategory.id}>
										{subcategory.name}
									</Option>
								))}
							</Select>
						</Form.Item>

						<Form.Item
							label="Description"
							name="description"
							rules={[
								{ required: true, message: "Please enter a description" },
							]}
							className="add-product__form-item">
							<Input.TextArea
								rows={4}
								placeholder="Enter product description"
							/>
						</Form.Item>
					</div>

					<div className="add-product__form-variants">
						<Form.List name="colors">
							{(fields, { add, remove }) => (
								<>
									{fields.map(({ key, name, fieldKey }) => (
										<div key={key} className="add-product__color-item">
											<Form.Item
												label="Color"
												name={[name, "colorId"]}
												fieldKey={[fieldKey, "colorId"]}
												className="add-product__form-item">
												<Select
													placeholder="Select color"
													loading={colorLoading}>
													{colors.map((color) => (
														<Option key={color.id} value={color.id}>
															{color.name}
														</Option>
													))}
												</Select>
											</Form.Item>

											<Form.Item
												label="Stock Quantity"
												name={[name, "stockQuantity"]}
												fieldKey={[fieldKey, "stockQuantity"]}
												rules={[
													{
														required: true,
														message: "Please enter the stock quantity",
													},
												]}
												className="add-product__form-item">
												<Input placeholder="Enter stock quantity" />
											</Form.Item>

											<Form.Item
												label="Price"
												name={[name, "price"]}
												fieldKey={[fieldKey, "price"]}
												rules={[
													{ required: true, message: "Please enter the price" },
												]}
												className="add-product__form-item">
												<Input placeholder="Enter price" />
											</Form.Item>

											<Form.Item
												label="Size"
												name={[name, "size"]}
												fieldKey={[fieldKey, "size"]}
												className="add-product__form-item">
												<Input placeholder="Enter size" />
											</Form.Item>

											<Form.Item
												label="Images"
												name={[name, "images"]}
												fieldKey={[fieldKey, "images"]}
												valuePropName="fileList"
												getValueFromEvent={(e) => e.fileList}
												className="add-product__form-item">
												<Upload listType="picture" beforeUpload={() => false}>
													<Button icon={<UploadOutlined />}>
														Upload Images
													</Button>
												</Upload>
											</Form.Item>

											<Button
												type="link"
												danger
												onClick={() => remove(name)}
												className="add-product__remove-color">
												Remove Variant
											</Button>
										</div>
									))}
									<Button
										type="dashed"
										onClick={() => add()}
										block
										className="add-product__add-color">
										Add Variant
									</Button>
									<Form.Item className="add-product__form-item">
										<Button
											type="primary"
											htmlType="submit"
											loading={loading}
											block
											className="add-product__submit-btn">
											Submit
										</Button>
									</Form.Item>
								</>
							)}
						</Form.List>
					</div>
				</Form>
			</div>
		</Main>
	);
};

export default AddProduct;
