import React from "react";
import { Result } from "antd";
import "./commingsoon.css";
import banner from "./banner.png";

const HomePage = () => {
	return (
		<div className="commingsoon-container">
			<Result
				icon={
					<>
						<img className="logo" src="./logo.png" alt="SHEeagle"></img>
						<img className="banner" src={banner} alt="SHEeagle"></img>
					</>
				}
				title={
					<div className="title">
						Something Amazing is <span>On Its Way!</span>
					</div>
				}
				subTitle={
					<div className="subtitle">
						Get ready to shop the latest trends, deals, and must-haves. Stay
						tuned—our store is launching soon!
					</div>
				}
			/>
		</div>
	);
};

export default HomePage;
